import React from "react";
import {
  Flex,
  Box,
  Link as ChakraLink,
  Text,
  Stack,
  Divider,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Image from "next/image";
import Logo from "src/img/logo.svg";
import Link from "next/link";
import { FooterData } from "@/Types/types";

import { configFile } from "@/config";

interface Props {
  menu: FooterData[];
}

function Footer({ menu }: Props) {
  return (
    <Box as="footer" backgroundColor="#fff" top="0px" width="100%">
      <Flex
        maxW="1216px"
        width="95%"
        m="0 auto 2.5rem auto"
        flexDirection={{ base: "column-reverse", lg: "row" }}
        pt={{ base: "44px", lg: "64px" }}
        align="flex-start"
        justifyContent={{ base: "flex-start", lg: "space-between" }}
      >
        <Box mt={{ base: "32px", lg: "0" }}>
          <Link prefetch={false} href="/">
            <Image src={Logo} alt="Guia Make" width="184" />
          </Link>
          <Text my="32px" fontSize="14px" color="gray.800" w="18.5rem">
            O intuito do Guia Make é apresentar nossa opinião a respeito dos
            produtos, ajudar as pessoas a fazer melhores escolhas e conhecer
            novos produtos.
          </Text>
          {/* <Image src={LogoInstagram} alt="Instagram" width={24} /> */}
        </Box>
        <Flex
          align="flex-start"
          columnGap="36px"
          as="nav"
          display="flex"
          flexDir={{ base: "column", md: "row" }}
          rowGap="40px"
        >
          <Stack minW="176px">
            <>
              <Text
                mb="24px"
                fontWeight={700}
                color={configFile.primaryAccentColor}
              >
                Maquiagem
              </Text>{" "}
              <ChakraLink prefetch={false}
                href="/mascara-de-cilios"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Máscara de Cílios
              </ChakraLink>
              <ChakraLink prefetch={false}
                href={"/maquiagem-para-noite"}
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Maquiagem para noite
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/maquiagem-simples"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Maquiagem simples
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/colorimetria-na-maquiagem/"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Colorimetria Maquiagem
              </ChakraLink>
              <ChakraLink prefetch={false}
                href={"/categoria/makes"}
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Ver todas <ArrowForwardIcon />
              </ChakraLink>
            </>
          </Stack>
          <Stack minW="176px">
            <>
              <Text mb="24px" fontWeight={700} color={configFile.primaryAccentColor}>
                Cosméticos
              </Text>{" "}
              <ChakraLink prefetch={false}
                href="/guia-protetor-solar"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Protetor Solar
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/agua-micelar-beyoung"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Água Micelar
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/agua-termal"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Água Termal
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/serum-facial"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Sérum Facial
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/categoria/cosmeticos"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Ver todas <ArrowForwardIcon />
              </ChakraLink>
            </>
          </Stack>
          <Stack minW="176px">
            <>
              <Text mb="24px" fontWeight={700} color={configFile.primaryAccentColor}>
                Marcas
              </Text>{" "}
              <ChakraLink prefetch={false}
                href="/sallve"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Sallve
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/simple-organic"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Simple Organic
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/principia"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Principia
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/ge-beauty"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                GE Beauty
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/categoria/marcas"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Ver todas <ArrowForwardIcon />
              </ChakraLink>
            </>
          </Stack>
          <Stack minW="176px">
            <>
              <Text mb="24px" fontWeight={700} color={configFile.primaryAccentColor}>
                Links rápidos
              </Text>{" "}
              <ChakraLink prefetch={false}
                href="/"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Home
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/contato"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Contato
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/contato"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Anuncie conosco
              </ChakraLink>
              <ChakraLink prefetch={false}
                href="/"
                fontWeight="400"
                fontSize="14px"
                display="block"
                paddingTop="12px"
                lineHeight="20px"
                color="gray.800"
                cursor="pointer"
                _hover={{ color: configFile.primaryAccentColor }}
                as={Link}
              >
                Contribuidoras
              </ChakraLink>
            </>
          </Stack>
        </Flex>
      </Flex>
      <Flex
        pb={{ base: "40px", lg: "60px" }}
        maxW="1216px"
        width="95%"
        m="0 auto"
        flexDirection={"column"}
      >
        <Divider />
        <Flex gap="32px">
          <Text mt="2.5rem" fontSize="14px" color="gray.800">
            © {new Date().getFullYear()} Guia Make
          </Text>
          <Text mt="2.5rem" fontSize="14px" color="gray.800">
            Política de Privacidade
          </Text>
          <Text mt="2.5rem" fontSize="14px" color="gray.800">
            Política de Cookies
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Footer;
